import React from "react";

import { BaseSection, BaseProps } from "./base";

import { Link } from "gatsby";

/** @jsx jsx */
import { jsx, Grid, Button, Flex } from "theme-ui";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { keyframes } from "@emotion/react";
import { BereavementContact, FormTypes } from "../form-contact";
const tick = keyframes`
    0% {
        transform: translateY(0);
        visibility: visible;
    }

    100% {
        transform: translateY(-768%);
    }
`;

type ListScrollProps = {
  subtitle: string;
  imgSrc: string;
  cta: React.ReactElement;
  children?: React.ReactNode;
};

export const ListScrollSection: React.FC = (props: ListScrollProps) => {
  const items = props.items || [];

  return (
    <section
      id="informed-decisions"
      sx={{
        backgroundColor: "darkBlue",
        color: "white",
        height: ["auto", "550px"],
        paddingTop: 0,
        paddingBottom: 0,
        overflow: "hidden",
      }}
    >
      <div class="aw-content">
        <Grid columns={["none", "3fr 2fr"]} gap={[2, "64px"]}>
          <div
            sx={{
              height: ["auto", "550px"],
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <h2
              sx={{
                fontFamily: "Georgia",
                fontWeight: 200,
                fontSize: ["40px", "34px", "52px"],
                marginBottom: 4,
              }}
            >
              {props.title}
            </h2>
            <p sx={{ fontSize: ["20px", "14px", "20px"] }}>
              You're already helping families in new ways, pushing the envelope and innovating in your community. You’re not
              afraid to try new things. You’re not afraid to be different.
              <br />
              <br />
              You're leading your market—<b>why should your technology partner be a
              follower?</b>
            </p>
            {/* <p sx={{ fontSize: ["20px", "14px", "20px"] }}>
              Forget fitting into templated software—our product molds into your
              unique process. No two funeral homes are the same, so you have
              complete control of the experience. We take the time to listen,
              configure everything for you, and migrate your historical data.
            </p> */}

            <div
              sx={{
                maxWidth: ["100%", "400px"],
                marginTop: "24px",
                marginBottom: ["8px", "32px"],
              }}
            >
              <BereavementContact
                formType={FormTypes.FUNERAL_HOMES}
                buttonText={"Sign up"}
              />
            </div>
          </div>

          <div>
            <div
              sx={{
                display: ["none", "inherit"],
                overflow: "hidden",
                height: "550px",
                boxSizing: "content-box",
                top: 0,
                alignSelf: "stretch",
                lineHeight: "38px",
              }}
            >
              <div
                sx={{
                  display: "inline-block",
                  height: "80px",
                  lineHeight: "80px",
                  whiteSpace: "nowrap",
                  boxSizing: "content-box",
                  animationIterationCount: "infinite",
                  animationTimingFunction: "linear",
                  animationName: tick,
                  animationDuration: "15s",
                }}
              >
                {items.concat(items).map((item) => {
                  return (
                    <p
                      sx={{
                        fontSize: "52px",
                        lineHeight: "1.5",
                        fontFamily: "Georgia",
                        marginBottom: "10px",
                        fontWeight: 200,
                      }}
                    >
                      {item}
                    </p>
                  );
                })}
              </div>
            </div>

            <div
              sx={{
                display: ["block", "none"],
                marginBottom: "24px",
                marginTop: "24px",
              }}
            >
              {items.map((item) => {
                return (
                  <p
                    sx={{
                      fontSize: "20px",
                      lineHeight: "1.5",
                      fontFamily: "Georgia",
                      marginBottom: "10px",
                      fontWeight: 200,
                    }}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
          </div>
        </Grid>
      </div>
    </section>
  );
};
