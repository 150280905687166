import React from "react";
import { graphql } from "gatsby";

import { Button, Link, Styled, Flex } from "theme-ui";
import Layout from "../components/layout-main";
import { trackButtonCta } from "../components/analytics";
import { FuneralHomeTestimonials } from "../constants/testimonials";
import { Routes } from "../constants/routes";
import { getViewportWidth } from "../components/dom";
import CalendlySection, {
  CalendlyEventTypes,
} from "../components/form-calendly";
import Img from "gatsby-image";

/** @jsx jsx */
import { jsx, Grid } from "theme-ui";
import Testimonials from "../components/testimonials";
import { ContactForm, FormTypes } from "../components/form-contact";
import { FeatureCarouselSection } from "../components/sections/feature-carousel";
import { ListScrollSection } from "../components/sections/list-scroll";
import { EffiesStory } from "./about";
import { FeatureCallouts } from "./online-planner";

class FeaturePanel extends React.Component {
  render() {
    const {
      altText,
      imageRef,
      title,
      subtitle,
      buttonText,
      circleColor,
      focusParam,
      buttonUrl,
      maxWidth,
    } = this.props;
    let photoWidth = maxWidth || "260px";
    return (
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "space-between",
          alignContent: "center",
          padding: ["auto", "auto", "0px 15px !important"],
          marginTop: ["20px", 0],
        }}
      >
        <div sx={{ height: "210px" }}>
          <Img
            title={altText}
            alt={altText}
            fluid={imageRef.childImageSharp.fluid}
            sx={{
              margin: "0 auto",
              width: [photoWidth, "150px", photoWidth],
              height: [photoWidth, "150px", photoWidth],
              marginBottom: ["1em", "2em"],
            }}
          />
        </div>
        <h3 sx={{ variant: "text.feature" }}>{title}</h3>

        <p
          sx={{
            marginBottom: ["20px", "50px"],
          }}
        >
          {subtitle}
        </p>

        <Link
          onClick={() => {
            trackButtonCta(buttonUrl || focusParam);
          }}
          href={
            buttonUrl
              ? buttonUrl
              : focusParam
              ? "/services?f=" + focusParam
              : "/services"
          }
        >
          <Button
            sx={{
              marginBottom: ["3em", "2em", "2em"],
            }}
            variant="blueButton"
          >
            {buttonText}
          </Button>
        </Link>
      </Flex>
    );
  }
}

/**
 * Quick video React component to grab the (accurate) viewport width
 * after the component has mounted.
 */
export class VideoSplashComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoUrl: this.props.videoUrl || "",
      poster: this.props.poster || "",
    };
  }

  componentDidMount = () => {
    if (this.state.videoUrl) {
      return;
    }
    const viewportWidth = getViewportWidth();
    let videoFilename = "New%20Splash";
    let videoSplashUrl;
    // if (viewportWidth > 1200) {
    //   // ["768px", "1025px", "1200px"]
    //   videoFilename = "New%20Splash.mp4";
    //   // string or 3rd array
    // } else if (viewportWidth > 768) {
    //   videoFilename = "New%20Splash.mp4";
    // } else {
    //   videoFilename = "New%20Splash.mp4";
    // }
    videoSplashUrl =
      // process.env.NODE_ENV === "development"
      //   ? `/images/${videoFilename}.mp4`
      //   :
      `//storage.googleapis.com/afterword-static/${videoFilename}.mp4`;

    this.setState({
      videoUrl: videoSplashUrl,
      poster: "https://storage.googleapis.com/afterword-static/July-poster.jpg",
    });
  };

  render = () => {
    return (
      <video
        autoPlay
        loop
        muted
        playsInline
        tabIndex="-1"
        poster={this.state.poster}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: ["auto", "100%"],
          height: "100%",
          outlineWidth: "0px",
          appearance: "none",
          display: "block",
        }}
      >
        {this.state.videoUrl && (
          <source src={this.state.videoUrl} type="video/mp4" />
        )}
      </video>
    );
  };
}

export const VideoSplash = (props) => {
  return (
    <section
      className="aw-splash"
      sx={{
        ...(props.containerSx || {}),
        // height: "100%",
        minHeight: "200px",
        maxHeight: "770px",
        padding: 0,
        display: "grid",
        gridTemplateRows: "auto auto auto auto auto",
        gridTemplateColumns: "auto",
        gridTemplateAreas: "none",
        textAlign: "center",
        backgroundColor: "var(--color-accent-dark)",
        alignContent: "center",
      }}
    >
      <div
        className="aw-splash-grid"
        sx={{
          gridArea: "1 / 1 / -1 / -1",
          pointerEvents: "none",
        }}
      >
        <div
          className="video-container"
          sx={
            {
              // zIndex: -1,
              height: ["400px", "auto"],
            }
          }
        >
          <div className="video-overlay"></div>
          <VideoSplashComponent
            videoUrl={props.videoUrl}
            poster={props.poster}
          />
        </div>
      </div>

      {/* <div
        sx={{
          display: "grid",
          // gridTemplateColumns: "repeat(12,minmax(0,1fr))",
          // alignItems: "center",
          // margin: "0 5rem",
          zIndex: 3,
        }}
      > */}
      {/* <div
          sx={{
            gridColumn: [
              // "span 5/span 5",
              // "span 6/span 6",
              // "span 5/span 5",
            ]
          }}
        > */}
      <Styled.h1
        id="splash-h1"
        sx={{
          color: "beige",
          marginBottom: ["30px", "20px"],
          pointerEvents: "none",
          gridArea: "2 / 1 / 2 / -1",
          alignSelf: "initial",
          zIndex: 1,

          lineHeight: "120%",
          marginTop: "0px",
          marginLeft: "1rem",
          marginRight: "1rem",
          // fontSize: "56px",
          padding: "0px",

          fontWeight: 500,
          textShadow: "0px 2px 1px #000000",

          // textAlign: "left",
        }}
      >
        {props.title}
      </Styled.h1>

      <Styled.h3
        id="splash-h3"
        sx={{
          color: "beige",
          pointerEvents: "none",
          gridArea: "3 / 1 / 3 / 1",
          fontSize: [
            "16px",
            "16px",
            "24px",
          ],
          margin: 0,
          zIndex: 1,

          marginLeft: "1rem",
          marginRight: "1rem",
        }}
      >
        {props.subtitle}
      </Styled.h3>
      <div
        sx={{
          gridArea: "4 / 1 / 4 / 1",
          // marginBottom: "initial",
          zIndex: 1,
          margin: "0 auto",
          marginTop: ["40px", "60px"],
        }}
      >
        {!props.buttonOverride && (
          <Link
            onClick={() => {
              trackButtonCta("video-hero");
            }}
            href={Routes.FUNERAL_HOMES}
          >
            <Button
              variant="secondary"
              sx={{
                borderColor: "black",
              }}
            >
              Get Started
            </Button>
          </Link>
        )}
        {props.buttonOverride && props.buttonOverride}
      </div>
      {/* </div> */}
      {/* </div> */}
    </section>
  );
};

const MainPage = ({ data, location }) => {
  const titleTextSize = { fontSize: ["40px", "52px"] };

  return (
    <Layout
      location={location}
      pageTitle="Making Funeral Planning Easier for Everyone"
    >
      <VideoSplash
        poster="https://storage.googleapis.com/afterword-static/NewSplash%20Poster.jpg"
        videoUrl="https://storage.googleapis.com/afterword-static/New Splashv2.mp4"
        containerSx={{ maxHeight: "770px", overflowY: "hidden" }}
        buttonOverride={
          <div sx={{
            width: ["100%", "400px"],
          }}>
          <ContactForm
            buttonVariant="blueButton"
            formType={FormTypes.FUNERAL_HOMES}
            formTitle=""
            formOutro={
              <span sx={{ color: "white", margin: "0 1rem", }}>
                Thanks for signing up. Our team will reach out to you shortly.
              </span>
            }
            calendlyMessageOverride={
              <span sx={{ color: "white", margin: "0 1rem", }}>
                If you'd like to schedule a chat, book time with us below.
              </span>
            }
            hideImage={true}
            buttonText="Book a demo"
            calendlyType={CalendlyEventTypes.FUNERAL_HOMES}
          />
          </div>
        }
        title={<>Making Funeral Planning Easier for Everyone</>}
        subtitle={
          <>
            Afterword supports the next-generation of funeral homes{" "}
            <br sx={{ display: ["none", "block"]}} />
            and the families they serve
          </>
        }
      />
      {/* <HeroSplashSection
        id="hero-splash"
        title={
          <>
            The new standard
            <br />
            in funeral management.
          </>
        }
        subtitle={"Afterword is built for you."}
        description={
          "Our software platform replaces most of your tools with a family-centric, all-in-one solution for managing your funeral home. Pre-need or at-need, online or in-person."
        }
        imgSrc={"https://ik.imagekit.io/aw/site/virtual-funeral-assistant.png"}
        // imgSrc={"/images/product/virtual-funeral-assistant.png"}
        imgFluid={data.splash.childImageSharp.fluid}
        cta={
          <ContactForm
            buttonVariant="blueButton"
            formType={FormTypes.FUNERAL_HOMES}
            formTitle=""
            formOutro="Thanks for signing up. Our team will reach out to you shortly."
            hideImage={true}
            buttonText="Sign up"
            calendlyType={CalendlyEventTypes.FUNERAL_HOMES}
          />
        }
      ></HeroSplashSection> */}

      <section
        id="features"
        sx={{ backgroundColor: "darkBlue", color: "white", paddingTop: "1rem" }}
      >
        <div className="aw-content">
          <FeatureCallouts
            laptop
            features={[
              {
                tag: "Video-based Ecommerce",
                title: "Forget what you know about online planning",
                subtitle: "",
                smallSubtitle: true,
                replaces: [],
                vidRef:
                  "https://storage.googleapis.com/afterword-static/product/Ecommerce%20assistant.mp4",
                poster:
                  "https://ik.imagekit.io/aw/tr:w-600/site/Ecommerce%20assistant.png",
                blurb: (
                  <div sx={{ marginTop: 4, marginBottom: 4 }}>
                    Help more families arrange services from wherever they’re
                    most comfortable. Guide them online as if they walked in
                    with educational videos describing the importance of funeral
                    service.
                  </div>
                ),
                cta: (
                  <div
                    sx={{
                      maxWidth: ["100%", "400px"],
                      margin: "0 auto",
                      marginTop: ["24px", "0px"],
                      marginBottom: ["8px", "0px"],
                    }}
                  >
                    <ContactForm
                      buttonVariant="blueButton"
                      formType={FormTypes.FUNERAL_HOMES}
                      formTitle=""
                      formOutro={
                        <span sx={{ color: "white" }}>
                          Thanks for signing up. Our team will reach out to you
                          shortly.
                        </span>
                      }
                      calendlyMessageOverride={
                        <span sx={{ color: "white" }}>
                          If you'd like to schedule a chat, book time with us
                          below.
                        </span>
                      }
                      hideImage={true}
                      buttonText="Schedule demo"
                      calendlyType={CalendlyEventTypes.FUNERAL_HOMES}
                    />
                  </div>
                ),
                callouts: [
                  {
                    imgSrc: "",
                    title: "Education from experts: you",
                    description: `Your communities are looking for funeral guidance, but they'll likely find inaccurate information online. Educate online families about the importance of funeral services.`,
                  },
                  {
                    imgSrc: "",
                    title: "More than packages",
                    description: `Forget fitting into three packages that cause more questions than answers. There are no limitations to the services families can select online.`,
                  },
                  {
                    imgSrc: "",
                    title: "Burial and Cremation",
                    description: `Online families are looking for more than a discount cremation brand. Offer all of your services in-person and online without decreasing ARPC.`,
                  },
                ],
              },
            ]}
          />
        </div>
      </section>

      <FeatureCarouselSection
        title={"Innovative Care at Every Step"}
        description={
          "Empower every director to provide the best care possible and ensure every family has a consistently polished experience. Every director is now your best director."
        }
      />

      <ListScrollSection
        title={"Bridging Tradition & Technology"}
        items={[
          "Case Management",
          "Ecommerce",
          "Secure Payments",
          "Reports & Analytics",
          "Video Education",
          "Flexible Packaging",
          "Easy Migration",
        ]}
      />

      <section className="aw-accent">
        <div className="aw-content">
          <Grid
            columns={["1fr", "1fr 1fr 1fr 1fr"]}
            sx={{
              "& > div": {
                minHeight: ["100px", "auto"],
              },
              "& > div > img": {
                "-webkit-filter": "grayscale(100%)",
                filter: "grayscale(100%)",
                margin: "auto auto",
              },
              gridGap: [3, 4],
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <Grid item>
              <img src="/images/press-fortune.png" width="200px" />
            </Grid>
            <Grid item>
              <img src="/images/press-tc.png" width="200px" />
            </Grid>
            <Grid item>
              <img src="/images/press-insider.webp" width="180px" />
            </Grid>
            <Grid item>
              <img src="/images/press-bloomberg.png" width="170px" />
            </Grid>
            {/* <Grid item>
              <img src="/images/press-toronto-star.png" width="200px" />
            </Grid> */}
          </Grid>
        </div>
      </section>

      <Testimonials
        id="testimonials"
        title="What our customers are saying"
        reviews={FuneralHomeTestimonials}
      />

      {/* <TwoColumnSection
        title={
          <>
            By tech pros,
            <br />
            for funeral pros.
          </>
        }
        description={
          "Built alongside funeral directors, Afterword is the product we wish we had when we needed it: a family-centric, all-in-one solution for managing your funeral home."
        }
        cta={{
          to: Routes.ABOUT,
          label: "Learn about our approach",
        }}
        imgSrc={
          "https://storage.googleapis.com/afterword-static-public/test.png"
        }
      /> */}

      <EffiesStory
        data={data}
        title="Effie's story — our co-founder & CEO"
        blurbs={[
          `I started Afterword after my dad died in August 2018. Never having planned a funeral before, my first instinct was to try to plan his service in the way that felt most comfortable to me – online. While I didn't grow up above a funeral home, Afterword is, in essence, a family business. My dad is the reason it exists, and the work we do is personal.`,

          `We know your work is personal, too. I’ve gained a deep appreciation for the crucial role that funeral directors play in the communities they serve. It’s because of this that we know software can never replace them. Regardless of age or generation, there will always be families who want to plan in-person. But there are also those, like me, who are more comfortable making these difficult decisions online. With this in mind, we've built the planning experience I wish I had years ago.`,
          // `Hi, I'm Effie. I started Afterword after my dad died in August 2018, and I planned
          // my first funeral. I was surprised that the process was so different
          // from anything else I had done before, and I
          // found it nearly impossible to find all the information I needed to
          // make the many important decisions.`,
          // `Ever since then we've worked with families & funeral homes to improve the planning experience. We want what you want—to provide the best outcome for your families—so our technology is built with them in mind.`,
          // "We embed ourselves within funeral homes to learn from and collaborate with funeral home all-stars and veterans. Built alongside funeral directors, Afterword is the product I wish I had when I needed it. And with your partnership, we'll continue innovating to help educate and comfort families.",
        ]}
      />

      <CalendlySection
        unaccented
        pageName="home"
        headerOverride={"Not sure where to start? Schedule a demo with us"}
        buttonOverride={"Schedule a demo"}
        calendlyType={CalendlyEventTypes.FUNERAL_HOMES}
        buttonVariant={"blueButton"}
      />
    </Layout>
  );
};

export default MainPage;

export const pageQuery = graphql`
  query {
    effieImage: file(relativePath: { regex: "/about-effie.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    zackImage: file(relativePath: { regex: "/about-zack.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    effiePhoto: file(relativePath: { regex: "/fe3.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    effie1: file(relativePath: { regex: "/effie1.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    effie2: file(relativePath: { regex: "/effie2.png/" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    splash: file(relativePath: { regex: "/virtual-funeral-assistant.png/" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
